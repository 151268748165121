/* eslint-disable @next/next/no-img-element */
import { useCallback, useRef } from 'react'
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom'

interface ICustomZoomProps {
  imgUrl: string | null
  isFullscreenVisible?: boolean
  isThumbnailVisible?: boolean
  openFullscreen?: () => void
  closeFullscreen: () => void
}

const CustomZoom = ({
  imgUrl,
  isFullscreenVisible,
  isThumbnailVisible,
  openFullscreen,
  closeFullscreen,
}: ICustomZoomProps) => {
  const imgRef = useRef<HTMLImageElement | null>(null)

  const onUpdate = useCallback(({ x, y, scale }: { x: number; y: number; scale: number }) => {
    const { current: img } = imgRef

    if (img) {
      const value = make3dTransformValue({ x, y, scale })
      img.style.transform = value
    }
  }, [])

  if (!imgUrl) return <></>

  return (
    <div>
      {/* 썸네일 이미지 */}
      {isThumbnailVisible && (
        <img
          src={imgUrl}
          alt="Zoomable"
          onClick={() => openFullscreen && openFullscreen()}
          className="cursor-pointer w-full h-auto"
        />
      )}

      {/* 전체화면 모드 */}
      {isFullscreenVisible && (
        <div
          className="fixed inset-0 bg-black bg-opacity-100 flex items-center justify-center z-50"
          onClick={closeFullscreen}>
          <button
            onClick={e => {
              e.stopPropagation()
              closeFullscreen()
            }}
            className="absolute top-0 my-[20px] mx-[16px] right-0 cursor-pointer z-50">
            <img src="/img/close.svg" alt="close icon" />
          </button>
          <div className="relative w-[100vw] h-[100vh] flex items-center justify-center overflow-hidden">
            <QuickPinchZoom onUpdate={onUpdate}>
              <img
                ref={imgRef}
                src={imgUrl}
                alt="Zoomable"
                className="w-full h-[800px] object-contain z-10"
                style={{ cursor: 'grab' }}
              />
            </QuickPinchZoom>
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomZoom
