/* eslint-disable @next/next/no-img-element */

import dayjs from 'dayjs'
import { i18n } from 'next-i18next'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { BottomSheet, Button } from '@/components/core-ui'
import { CustomZoom } from '@/components/utils'
import { useRefreshContext } from '@/shared/contexts'
import { useCustomZoom } from '@/shared/hooks'
import { usePostBenefitsStatus } from '@/shared/services/utilities'
import { IDialogProps } from '@/shared/types'
import { cx } from '@/shared/utils'
import { selectCurrentUtilityValue, selectSelectedBenefit } from '@/store/claim/claimSlice'
import { useAppSelector } from '@/store/hooks'

export const ClaimUseBenefitsCommonBottomSheet = ({ isOpened, closeDialog }: IDialogProps) => {
  const { isFullscreenVisible, handleOpenFullscreen, handleCloseFullscreen } = useCustomZoom()
  const currentUtilityValue = useAppSelector(selectCurrentUtilityValue)

  const { t } = useTranslation(['common', 'web-claim', 'web-modal'])
  const locales = i18n?.language ?? 'ko'
  dayjs.locale(locales)

  const selectedBenefit = useAppSelector(selectSelectedBenefit)

  const { statusId, itemId, selectedTitle, selectedBenefitDate, selectedDirectionalMap } =
    selectedBenefit

  const [isUsed, setIsUsed] = useState(false)

  const { refreshRefetch } = useRefreshContext()

  const { mutate, isLoading } = usePostBenefitsStatus()

  const isCanUseBenefits = useMemo(() => {
    if (!selectedBenefitDate?.selectedEntryTime) return
    const now = new Date().getTime()
    const dDay = new Date(selectedBenefitDate.selectedEntryTime).getTime()
    const distance = dDay - now
    return distance < 0
  }, [selectedBenefitDate?.selectedEntryTime])

  const isButtonDisabled = useMemo(() => {
    return !isCanUseBenefits || isUsed
  }, [isCanUseBenefits, isUsed])

  const buttonSubTitle = useMemo(() => {
    const now = dayjs()
    const nowData = now.format('MM/DD(ddd)')
    if (isUsed) return `${nowData} ${t('common:common.item-benefit-status-used-web')}`
    if (isCanUseBenefits) return t('web-claim:claim.common-bottom-modal-label-on-use-time')
    else return t('web-claim:claim.common-bottom-modal-label-before-use-time')
  }, [isCanUseBenefits, isUsed, t])

  const handleClose = useCallback(() => {
    refreshRefetch()
    setIsUsed(false)
    closeDialog()
  }, [closeDialog, refreshRefetch])

  const onclickUseBenefits = useCallback(() => {
    if (!isUsed && statusId && itemId) {
      mutate(
        {
          statusId,
          itemId,
        },
        {
          onSuccess: () => {
            setIsUsed(true)
            refreshRefetch()
          },
          onError: () => {
            setIsUsed(false)
            toast(t('web-modal:web-modal.confirm-claim-fail'), {
              toastId: 'useBenefit',
            })
          },
        },
      )
    }
  }, [isUsed, itemId, mutate, refreshRefetch, statusId, t])

  const UseBenefitsButton = useCallback(() => {
    return (
      <Button
        className={cx(
          `${
            !isUsed && 'claim-button'
          } p-[10px] flex flex-col items-center justify-center gap-1 w-full h-[90px] rounded-lg`,
        )}
        disable={isButtonDisabled}
        size={'lg'}
        spinner={isLoading}
        onClick={onclickUseBenefits}>
        <div className={cx(`${!isUsed ? 'text-textBlack' : 'text-textSecondary'}`)}>
          <div className="font-semibold text-2xl">
            {isUsed
              ? t('web-claim:claim.item-tab-used-pass-used')
              : t('web-claim:claim.item-cta-label-claim')}
          </div>
          {buttonSubTitle && (
            <div className="text-sm font-norma whitespace-pre-line">{buttonSubTitle}</div>
          )}
        </div>
      </Button>
    )
  }, [buttonSubTitle, isButtonDisabled, isLoading, isUsed, onclickUseBenefits, t])

  const NoticeDescription = useCallback(() => {
    if (selectedDirectionalMap !== null) {
      return (
        <div className="bg-bgTertiary mt-10 mb-10 flex gap-1 rounded-lg text-sm p-4 w-full text-appTextSecondary items-start">
          <p className="whitespace-pre-line">
            {t('web-claim:web-claim.msg-qr-bottom-modal-coupon')}
          </p>
        </div>
      )
    }
    if (currentUtilityValue?.isDive) {
      return (
        <div className="bg-bgTertiary mt-10 mb-10 flex gap-1 rounded-lg text-sm p-4 w-full text-appTextSecondary items-start">
          <img src="/img/hc-alert-line.svg" alt="" className="min-w-[20px] min-h-[20px]" />
          <p className="whitespace-pre-line">{t('web-claim:web-claim.msg-confirm-staff-use')}</p>
        </div>
      )
    } else
      return (
        <div className="bg-bgTertiary mt-10 mb-10 flex gap-1 rounded-lg text-sm p-4 w-full text-appTextSecondary items-start">
          <p className="whitespace-pre-line">
            {t('web-claim:claim.common-bottom-modal-label-staff')}
          </p>
        </div>
      )
  }, [currentUtilityValue?.isDive, selectedDirectionalMap, t])

  const title = useMemo(() => {
    if (currentUtilityValue?.isDive) return t('web-claim:web-claim.title-bottomsheet-welcomkit')
    else return t('web-claim:claim.item-cta-label-use-benefits')
  }, [currentUtilityValue?.isDive, t])

  const BenefitTitle = useCallback(() => {
    return (
      <div className="flex flex-col items-center">
        <div className="w-full text-xl font-semibold text-center overflow-hidden text-ellipsis line-clamp-2">
          {selectedTitle?.title}
        </div>
        <div className="w-full text-lg font-medium text-center overflow-hidden text-ellipsis line-clamp-2 mt-1">
          {selectedTitle?.subTitle}
        </div>
      </div>
    )
  }, [selectedTitle?.subTitle, selectedTitle?.title])

  if (!isOpened) return <></>

  return (
    <BottomSheet closeSheet={handleClose} center={true}>
      <div className="relative h-full px-4">
        <button
          onClick={handleClose}
          className="absolute my-[20px] mx-[16px] right-0 cursor-pointer">
          <img src="/img/close.svg" alt="close icon" />
        </button>
        <div className="h-[64px] flex justify-center items-center text-center">
          <div className="text-lg leading-normal font-medium">{title}</div>
        </div>
        <div className="h-[600px] pt-[25px] pb-[100px] overflow-y-auto scrollbar-hide">
          <div className="flex flex-col gap-2 items-center justify-center w-full">
            <BenefitTitle />
            {selectedTitle?.bottomSheetSubText && (
              <div className="text-textAccent text-sm">{selectedTitle?.bottomSheetSubText}</div>
            )}
          </div>
          <div className="mt-10 w-full">
            <UseBenefitsButton />
          </div>
          <NoticeDescription />
          <CustomZoom
            isThumbnailVisible
            imgUrl={selectedDirectionalMap}
            isFullscreenVisible={isFullscreenVisible}
            openFullscreen={handleOpenFullscreen}
            closeFullscreen={handleCloseFullscreen}
          />
        </div>
      </div>
    </BottomSheet>
  )
}
