import { useCallback, useState } from 'react'

export const useCustomZoom = () => {
  const [isFullscreenVisible, setIsFullscreenVisible] = useState<boolean>(false)

  const handleOpenFullscreen = useCallback(() => {
    setIsFullscreenVisible(true)
  }, [])

  const handleCloseFullscreen = useCallback(() => {
    setIsFullscreenVisible(false)
  }, [])

  return {
    isFullscreenVisible,
    handleOpenFullscreen,
    handleCloseFullscreen,
  }
}
